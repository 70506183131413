.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #17191B; /* remplacer cette valeur par la couleur de fond souhaitée */
  }
  
  .sidebar-button {
    display: block;
    text-decoration: none;
    color: white;
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .sidebar-button:hover {
    color: #ff7c3a; /* Vous pouvez remplacer cette couleur par celle de votre choix pour l'effet de survol */
  }
  
  .sidebar-logo {
    width: 100px; /* Vous pouvez ajuster cette valeur en fonction de la taille souhaitée pour le logo */
    margin-top: 10px; /* Ajoutez cette ligne pour ajouter de l'espace au-dessus du logo */
    margin-bottom: 0px;
  }
  
  
  .sidebar-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  